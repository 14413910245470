import { CHAIN_ID } from '..'

export const tethysList = [
  {
    chainId: 6969,
    address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    decimals: 18,
    name: 'Tomb',
    symbol: 'TOMB',
    logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000100',
    decimals: 6,
    name: 'USD Coin',
    symbol: 'USDC',
    logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    name: 'Fantom',
    symbol: 'FTM',
    logoURI: 'https://assets.lif3.com/swap/tokens/FTM.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000101',
    decimals: 18,
    name: 'TSHARE',
    symbol: 'TSHARE',
    logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000102',
    decimals: 18,
    name: 'BASED',
    symbol: 'BASED',
    logoURI: 'https://assets.lif3.com/swap/tokens/BASED.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000103',
    decimals: 18,
    name: 'BSHARE',
    symbol: 'BSHARE',
    logoURI: 'https://assets.lif3.com/swap/tokens/BSHARE.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000104',
    decimals: 18,
    name: 'TBOND',
    symbol: 'TBOND',
    logoURI: 'https://assets.lif3.com/swap/tokens/TBOND.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000106',
    decimals: 8,
    name: 'Bitcoin',
    symbol: 'BTC',
    logoURI: 'https://assets.lif3.com/swap/tokens/BTC.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000107',
    decimals: 18,
    name: 'ZooCoin',
    symbol: 'ZOO',
    logoURI: 'https://assets.lif3.com/swap/tokens/ZOO.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000108',
    decimals: 18,
    name: 'LIF3',
    symbol: 'LIF3',
    logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x4200000000000000000000000000000000000109',
    decimals: 18,
    name: 'LSHARE',
    symbol: 'LSHARE',
    logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x94bb580D7f99c30F125669bFAF8164d5Ff6436e7',
    decimals: 18,
    name: 'L3USD',
    symbol: 'L3USD',
    logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0xb92c63C2E9F72946cd483e6f554C682216904ec0',
    decimals: 18,
    name: 'DAI',
    symbol: 'DAI',
    logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x88CF9b1FE151a0B143A54Ef606317F3852e129CB',
    decimals: 18,
    name: 'WETH',
    symbol: 'WETH',
    logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x7D4a600ADBC6BF95FCFbC01CcEE1431919752aad',
    decimals: 18,
    name: 'FUSDT',
    symbol: 'FUSDT',
    logoURI: 'https://assets.lif3.com/swap/tokens/FUSDT.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x6a2a260FA68262E44b231269807990e83442456a',
    decimals: 0,
    name: 'ZOO',
    symbol: 'ZOO',
    logoURI: 'https://assets.lif3.com/swap/tokens/ZOO.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x63Fb58f5CF7a6ee4c7E1075754235f1FA0199249',
    decimals: 0,
    name: 'Staked USDC',
    symbol: 'stlUSDC',
    logoURI: 'https://assets.lif3.com/swap/tokens/STLUSDC.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x89ccF2c7Fa19324dB6E44Cbce366a6E68FAf92e2',
    decimals: 0,
    name: 'Staked Tomb',
    symbol: 'stlTOMB',
    logoURI: 'https://assets.lif3.com/swap/tokens/STLTOMB.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0x68cDbC441BAD0476db6750D1358F2Ea4dEB0d016',
    decimals: 0,
    name: 'Staked Lif3',
    symbol: 'stlLIF3',
    logoURI: 'https://assets.lif3.com/swap/tokens/STLLIF3.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },
  {
    chainId: 6969,
    address: '0xe70d837CCC4457fD7a35FFA5cA4E90AEa989C0aE',
    decimals: 0,
    name: 'Staked L3USD',
    symbol: 'stlL3USD',
    logoURI: 'https://assets.lif3.com/swap/tokens/STLL3USD.svg',
    socials: {},
    slippage: 0,
    website: '',
    level: 1,
  },

  {
    address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    chainId: 250,
    name: 'WFTM',
    symbol: 'WFTM',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/WFTM.svg',
  },
  {
    address: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    chainId: 250,
    name: 'TOMB',
    symbol: 'TOMB',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
  },
  {
    address: '0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    chainId: 250,
    name: 'TSHARE',
    symbol: 'TSHARE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
  },
  {
    address: '0x24248CD1747348bDC971a5395f4b3cd7feE94ea0',
    chainId: 250,
    name: 'TBOND',
    symbol: 'TBOND',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/TBOND.svg',
  },
  {
    address: '0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    chainId: 250,
    name: 'MAI',
    symbol: 'MAI',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/MAI.svg',
  },
  {
    address: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    chainId: 250,
    name: 'BASED',
    symbol: 'BASED',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/BASED.svg',
  },
  {
    address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    chainId: 250,
    name: 'Wrapped Bitcoin',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://assets.lif3.com/swap/tokens/BTC.svg',
  },
  {
    address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    chainId: 250,
    name: 'Wrapped Ethereum',
    symbol: 'WETH',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
  },
  {
    address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    chainId: 250,
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
  },
  {
    address: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    chainId: 250,
    name: 'fUSDT',
    symbol: 'fUSDT',
    decimals: 6,
    logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
  },
  {
    address: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    chainId: 250,
    name: 'MIM',
    symbol: 'MIM',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/MIM.svg',
  },
  {
    address: '0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
    chainId: 250,
    name: 'FUSD',
    symbol: 'FUSD',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/FUSD.svg',
  },
  {
    address: '0x49C290Ff692149A4E16611c694fdED42C954ab7a',
    chainId: 250,
    name: 'BSHARE',
    symbol: 'BSHARE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/BSHARE.svg',
  },
  {
    address: '0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    chainId: 250,
    name: 'TREEB',
    symbol: 'TREEB',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/TREEB.svg',
  },
  {
    address: '0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    chainId: 250,
    name: 'ZOO',
    symbol: 'ZOO',
    decimals: 0,
    logoURI: 'https://assets.lif3.com/swap/tokens/ZOO.svg',
  },
  {
    address: '0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    chainId: 250,
    name: 'SCREAM',
    symbol: 'SCREAM',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/SCREAM.svg',
  },
  {
    address: '0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
    chainId: 250,
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/AVAX.svg',
  },
  {
    address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    chainId: 250,
    name: 'DAI',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
  },
  {
    address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    chainId: 250,
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/BNB.svg',
  },
  {
    address: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    chainId: 250,
    name: 'CRV',
    symbol: 'CRV',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/CRV.svg',
  },
  {
    address: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    chainId: 250,
    name: 'LINK',
    symbol: 'LINK',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/LINK.svg',
  },
  {
    address: '0xbf60e7414EF09026733c1E7de72E7393888C64DA',
    chainId: 250,
    name: 'LIF3',
    symbol: 'LIF3',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
  },
  {
    address: '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45',
    chainId: 250,
    name: 'LSHARE',
    symbol: 'LSHARE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
  },
  {
    address: '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A',
    chainId: 250,
    name: 'L3USD',
    symbol: 'L3USD',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
  },
  {
    address: '0xcc3BED3712CbC88994f85c8a06405A6CE807ba1f',
    chainId: 250,
    name: 'QUINT',
    symbol: 'QUINT',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/QUINT.svg',
  },

  {
    address: '0x5248f6c6196EB9DF6fa4870c66f97638F33D13B0',
    chainId: CHAIN_ID.BSC,
    name: 'TSHARE',
    symbol: 'TSHARE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
  },
  {
    address: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
    chainId: CHAIN_ID.BSC,
    name: 'LSHARE',
    symbol: 'LSHARE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
  },
  {
    address: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
    chainId: CHAIN_ID.BSC,
    name: 'LIF3',
    symbol: 'LIF3',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
  },
  {
    address: '0xDEa12C8C23994EA2D88eD99eE1bdc0FF56f7F9d1',
    chainId: CHAIN_ID.BSC,
    name: 'L3USD',
    symbol: 'L3USD',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
  },
  {
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    chainId: CHAIN_ID.BSC,
    name: 'Wrapped BNB',
    symbol: 'WBNB',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/BNB.svg',
  },
  {
    address: '0x8f01d597d2022656494E30fb76eccf1eEA2E092e',
    chainId: CHAIN_ID.BSC,
    name: 'TOMB',
    symbol: 'TOMB',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
  },
  {
    address: '0x55d398326f99059fF775485246999027B3197955',
    chainId: CHAIN_ID.BSC,
    name: 'Tether',
    symbol: 'USDT',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
  },
  {
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    chainId: CHAIN_ID.BSC,
    name: 'Binance USD',
    symbol: 'BUSD',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/BUSD.svg',
  },
  {
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    chainId: CHAIN_ID.BSC,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
  },
  {
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    chainId: CHAIN_ID.BSC,
    name: 'Bitcoin',
    symbol: 'BTCB',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/BTC.svg',
  },
  {
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    chainId: CHAIN_ID.BSC,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/ETH.svg',
  },
  {
    address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    chainId: CHAIN_ID.BSC,
    name: 'Pancake Swap',
    symbol: 'CAKE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/CAKE.svg',
  },
  {
    address: '0x64619f611248256F7F4b72fE83872F89d5d60d64',
    chainId: CHAIN_ID.BSC,
    name: 'QUINT',
    symbol: 'QUINT',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/QUINT.svg',
  },
  {
    address: '0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1',
    chainId: CHAIN_ID.BSC,
    name: 'Wombat',
    symbol: 'WOM',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/WOM.svg',
  },
  {
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    chainId: CHAIN_ID.BSC,
    name: 'Pancake Swap',
    symbol: 'CAKE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/CAKE.svg',
  },
  {
    address: '0xFB40b1eFe90D4b786D2D9d9dc799B18BDe92923b',
    chainId: CHAIN_ID.POLYGON,
    name: 'LSHARE',
    symbol: 'LSHARE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
  },
  {
    address: '0x0e98C977B943f06075b2D795794238fBfB9b9a34',
    chainId: CHAIN_ID.POLYGON,
    name: 'TOMB',
    symbol: 'TOMB',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
  },
  {
    address: '0x2D1c6a7362E75d88B4Cf3b66b83052775846E2b8',
    chainId: CHAIN_ID.POLYGON,
    name: 'TSHARE',
    symbol: 'TSHARE',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
  },
  {
    address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
    chainId: CHAIN_ID.POLYGON,
    name: 'L3USD',
    symbol: 'L3USD',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
  },
  {
    address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    chainId: CHAIN_ID.POLYGON,
    name: 'WBTC',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://assets.lif3.com/swap/tokens/WBTC.svg',
  },
  {
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    chainId: CHAIN_ID.POLYGON,
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/ETH.svg',
  },
  {
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    chainId: CHAIN_ID.POLYGON,
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/MATIC.svg',
  },
  {
    address: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
    chainId: CHAIN_ID.POLYGON,
    name: 'QUICK',
    symbol: 'QUICK',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/QUICK.svg',
  },
  {
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    chainId: CHAIN_ID.POLYGON,
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
  },
  {
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    chainId: CHAIN_ID.POLYGON,
    name: 'USDT',
    symbol: 'USDT',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
  },
  {
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    chainId: CHAIN_ID.POLYGON,
    name: 'DAI',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
  },
  {
    address: '0x56ac3Cb5E74b8A5A25ec9Dc05155aF1dad2715bd',
    chainId: CHAIN_ID.POLYGON,
    name: 'LIF3',
    symbol: 'LIF3',
    decimals: 18,
    logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
  },
]
