import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
import MetisLogo from '../../assets/METIS.svg'
import { tethysList } from '../../constants/token/tethys.ts'
import EmptyCoinBg from '../../assets/EmptyCoinBg.png'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 100%;
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

const StyledIdenticonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  background-image: url(${EmptyCoinBg});
  background-repeat: no-repeat;
  background-size: contain;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
`

export default function TokenLogo({ address, header = false, size = '24px', coinName = '', sizeraw = 10, ...rest }) {
  const [error, setError] = useState(false)
  const ref = useRef()

  const formattedCoinName = coinName.slice(0, 1)

  // useEffect(() => {
  //   if (address && ref.current) {
  //     ref.current.innerHTML = ''
  //     ref.current.appendChild(Jazzicon(size, parseInt(address.slice(2, 10), 16)))
  //   }
  // }, [address, error])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <StyledIdenticonContainer size={size} sizeraw={sizeraw} ref={ref} {...rest}>
          {formattedCoinName}
        </StyledIdenticonContainer>
      </Inline>
    )
  }

  // hard coded fixes for trust wallet api issues
  if (address?.toLowerCase() === '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb') {
    address = '0x42456d7084eacf4083f1140d3229471bba2949a8'
  }

  if (address?.toLowerCase() === '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f') {
    address = '0xc011a72400e58ecd99ee497cf89e3775d4bd732f'
  }

  if (address?.toLowerCase() === '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={MetisLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  const path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${isAddress(
    address
  )}/logo.png`

  const localpath = tethysList.filter((a) => a.address === isAddress(address))

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={localpath[0] ? localpath[0].logoURI : ''}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
