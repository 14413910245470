import React, { useState } from 'react'
import styled from 'styled-components'

import Row, { RowBetween } from '../Row'
import { AutoColumn } from '../Column'
import { ChevronDown as Arrow } from 'react-feather'
import { TYPE } from '../../Theme'
import { StyledIcon } from '..'

const Wrapper = styled.div`
  z-index: 20;
  position: relative;
  border: 1px solid ${({ theme }) => theme.green};
  width: 100px;
  padding: 4px 10px;
  padding-right: 6px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 34px;
  padding-top: 40px;
  width: calc(100% - 40px);
  background: linear-gradient(
    180deg,
    rgba(54, 61, 77, 0.55) 0%,
    rgba(49, 56, 69, 0.55) 49.48%,
    rgba(43, 48, 60, 0.55) 69.79%,
    rgba(28, 30, 39, 0.55) 100%
  );
  box-shadow: 0px 38px 54px rgba(0, 0, 0, 0.13), 0px 7.6px 8.775px rgba(0, 0, 0, 0.065);
  border: 1px solid ${({ theme }) => theme.green};
  padding: 10px 16px;
  border-radius: 10px;
  width: calc(100% - 20px);
  font-weight: 500;
  font-size: 14px;
  backdrop-filter: blur(15px);

  color: black;
  :hover {
    cursor: pointer;
  }
`

const ArrowStyled = styled(Arrow)`
  height: 20px;
  width: 20px;
`

const DropdownSelect = ({ options, active, setActive, color }) => {
  const [showDropdown, toggleDropdown] = useState(false)

  return (
    <Wrapper open={showDropdown} color={color}>
      <RowBetween onClick={() => toggleDropdown(!showDropdown)} justify="center">
        <TYPE.main>{active}</TYPE.main>
        <StyledIcon style={{ width: '20px', height: '20px' }}>
          <ArrowStyled />
        </StyledIcon>
      </RowBetween>
      {showDropdown && (
        <Dropdown>
          <AutoColumn gap="20px">
            {Object.keys(options).map((key, index) => {
              let option = options[key]
              return (
                option !== active && (
                  <Row
                    onClick={() => {
                      toggleDropdown(!showDropdown)
                      setActive(option)
                    }}
                    key={index}
                  >
                    <TYPE.body fontSize={14}>{option}</TYPE.body>
                  </Row>
                )
              )
            })}
          </AutoColumn>
        </Dropdown>
      )}
    </Wrapper>
  )
}

export default DropdownSelect
