import React from 'react'
import styled from 'styled-components'
import { DiscordIcon } from '../Icons/DiscordIcon'
import { TwitterIcon } from '../Icons/TwitterIcon'
import { TelegramIcon } from '../Icons/TelegramIcon'
import { MediumIcon } from '../Icons/MediumIcon'
import { GithubIcon } from '../Icons/GithubIcon'

// import { AuditProviderLogo } from './svg/AuditProviderLogo'
import { ReactComponent as AuditProviderLogo } from '../../assets/TombChain.svg'
import { ReactComponent as LifMini } from '../../assets/lifmini.svg'
import { ReactComponent as FantomIcon } from '../../assets/fantom.svg'
import { ReactComponent as SkullIcon } from '../../assets/skull.svg'

import dayjs from 'dayjs'
import { rgba } from 'polished'
import { InstagramIcon } from '../Icons/InstagramIcon'

const Wrapper = styled.div<{ isLanding?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 140px;
  color: ${({ theme }) => theme.white};
  overflow: hidden;

  @media screen and (max-width: 980px) {
    padding-top: 20px;
    margin-bottom: 76px;
  }
`
const WrapperInner = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: flex-start;
  column-gap: 30px;

  @media screen and (max-width: 720px) {
    padding: 0 10px;
  }
`

const FooterLink = styled.a`
  font-size: 14px;
  color: inherit;
  opacity: 0.5;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }
`

const FooterLinksWrapper = styled.div<{ isLanding?: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  grid-column-start: 5;
  grid-column-end: 13;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.white};

  @media screen and (max-width: 1200px) {
    grid-column: span 12;
    margin-bottom: 48px;
  }
  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 48px;
    margin-bottom: 48px;
  }
`

const FooterLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
`

const FooterLinksLabel = styled.div`
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  line-height: 24px;
`

const FooterSocialsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 40px;
  row-gap: 24px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  color: ${({ theme }) => rgba(theme.footerSocials, 0.5)};

  @media screen and (max-width: 1200px) {
    grid-row-start: 2;
    grid-column: span 12;
    column-gap: 48px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 500px) {
    justify-content: center;
  }
`

const FooterText = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 55px;
  margin-bottom: 20px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  gap: 12px;

  @media screen and (max-width: 1200px) {
    grid-row-start: 3;
    grid-column: span 12;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`

const FooterTextItem = styled.p`
  display: inline-block;
  margin: 0;

  & > * {
    display: inline-block;
    vertical-align: middle;
  }
`

const FooterSocialItem = styled.a`
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => rgba(theme.footerSocials, 0.8)};
  }
`

const CopyrightRow = styled.div`
  grid-row-start: 2;
  grid-column: span 12;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 30px;
  padding: 16px 20px 30px;
  align-items: center;
  margin: 0 -20px;

  @media screen and (max-width: 1200px) {
    grid-row-start: 4;
  }

  @media screen and (max-width: 960px) {
    padding-bottom: 16px;
  }

  @media screen and (max-width: 720px) {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding: 14px 10px;
    margin: 0 -10px;
  }
`

const FooterCopyrightText = styled.div`
  grid-column: span 6;
  color: inherit;
  font-size: 15px;
  margin-right: 12px;
  opacity: 0.5;

  @media screen and (max-width: 720px) {
    grid-column: span 4;
  }
`

const FooterAuditText = styled.div`
  color: inherit;
  font-size: 15px;
  font-weight: 500;
  margin-right: 12px;
  transition: all 0.3s;
`

const FooterAuditWrapper = styled.a<{ isLanding?: boolean }>`
  grid-column: -1;
  max-width: 238px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 7px 21px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => rgba(theme.white, 0.2)};
  white-space: nowrap;
  color: ${({ theme }) => rgba(theme.white, 0.5)};
  transition: border-color 0.3s;

  @media screen and (max-width: 1200px) {
    margin-right: 30px;
  }

  @media screen and (max-width: 960px) {
    margin-right: 0;
  }

  @media screen and (max-width: 720px) {
    grid-column: -1 / -9;
    margin-left: auto;
  }

  &:hover {
    border-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.white};
  }

  &:hover ${FooterAuditText} {
    opacity: 1;
  }
`

const FooterAuditLogo = styled.div`
  width: 103px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {
  isLanding?: boolean
}

export const FooterGlobal: React.FC<Props> = (props) => {
  const { isLanding } = props

  return (
    <Wrapper isLanding={isLanding}>
      <WrapperInner>
        <FooterLinksWrapper isLanding={isLanding}>
          <FooterLinksColumn>
            <FooterLinksLabel>Tokens</FooterLinksLabel>
            <FooterLink href="https://www.coingecko.com/en/coins/lif3" target="_blank">
              LIF3 Coingecko
            </FooterLink>
            <FooterLink href="https://www.coingecko.com/en/coins/lif3-lshare" target="_blank">
              LSHARE Coingecko
            </FooterLink>
            <FooterLink href="https://www.coingecko.com/en/coins/tomb/" target="_blank">
              TOMB Coingecko
            </FooterLink>
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksLabel>LIF3 Dex</FooterLinksLabel>
            <FooterLink href="https://www.coingecko.com/en/exchanges/lif3/" target="_blank">
              Coingecko
            </FooterLink>
            <FooterLink href="https://www.geckoterminal.com/tombchain/lif3/pools/" target="_blank">
              Geckoterminal
            </FooterLink>
            <FooterLink href="https://dexscreener.com/tombchain/lif3" target="_blank">
              Dex Screener
            </FooterLink>
            {/* <FooterLink href="https://coinmarketcap.com/exchanges/tethys" target="_blank">
              CoinMarketCap
            </FooterLink> */}
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksLabel>Tomb Chain</FooterLinksLabel>
            <FooterLink href="https://tombchain.com/" target="_blank">
              Tomb Chain
            </FooterLink>
            <FooterLink href="https://tombscout.com/" target="_blank">
              TombScout Block Explorer
            </FooterLink>
            <FooterLink href="https://lif3.com/bridge/" target="_blank">
              Bridge
            </FooterLink>
            {/* <FooterLink href="https://oportal.boringdao.com/twoway" target="_blank">
              BoringDAO
            </FooterLink>
            <FooterLink href="https://app.multichain.org/#/router" target="_blank">
              Multichain
            </FooterLink> */}
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksLabel>Tomb on Fantom</FooterLinksLabel>
            <FooterLink href="https://tomb.com/" target="_blank">
              Tomb
            </FooterLink>
            <FooterLink href="https://swap.tomb.com/" target="_blank">
              Tomb Swap
            </FooterLink>
            <FooterLink href="https://info.swap.tomb.com/" target="_blank">
              Tomb Swap Info
            </FooterLink>
          </FooterLinksColumn>
        </FooterLinksWrapper>
        <FooterSocialsWrapper>
          <FooterSocialItem target="_blank" href="https://twitter.com/Official_LIF3">
            <TwitterIcon />
          </FooterSocialItem>
          <FooterSocialItem target="_blank" href="https://discord.gg/vANnESmVdz">
            <DiscordIcon />
          </FooterSocialItem>
          <FooterSocialItem target="_blank" href="https://t.me/Lif3_Official">
            <TelegramIcon />
          </FooterSocialItem>
          <FooterSocialItem target="_blank" href="https://www.instagram.com/lif3official/">
            <InstagramIcon />
          </FooterSocialItem>
          {/* <FooterSocialItem target="_blank" href="https://medium.com/@tethysfinance">
            <MediumIcon />
          </FooterSocialItem> */}
          {/* <FooterSocialItem target="_blank" href="https://github.com/Tethys-Finance">
            <GithubIcon />
          </FooterSocialItem> */}
        </FooterSocialsWrapper>
        <FooterText></FooterText>
        <CopyrightRow>
          <FooterCopyrightText>© {dayjs().format('YYYY')} Lif3</FooterCopyrightText>
          <FooterAuditWrapper isLanding={isLanding} href="https://tombchain.com/" target="_blank">
            <FooterAuditText>Powered by</FooterAuditText>
            <FooterAuditLogo>
              <AuditProviderLogo />
            </FooterAuditLogo>
          </FooterAuditWrapper>
        </CopyrightRow>
      </WrapperInner>
    </Wrapper>
  )
}
