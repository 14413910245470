import React from 'react'
import { LinkProps, useLocation, Link } from 'react-router-dom'
import { Link as RebassLink } from 'rebass'

export const LinkWithQuery: React.FC = ({ children, to, external, ...rest }: LinkProps & { external?: boolean }) => {
  const { search } = useLocation()

  return (
    <RebassLink
      to={to + search}
      target={external ? '_blank' : null}
      rel={external ? 'noopener noreferrer' : null}
      {...rest}
    >
      {children}
    </RebassLink>
  )
}

export const LinkWithQueryDefault: React.FC = ({ children, to, ...rest }: LinkProps) => {
  const { search } = useLocation()

  return (
    <Link to={to + search} {...rest}>
      {children}
    </Link>
  )
}
