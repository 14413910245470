export const getFactoryAddress = (chainId) => {
  const id = Number(chainId)

  switch (id) {
    case 56:
      return '0x3fb1e7d5d9c974141a5b6e5fa4edab0a7aa15c6a'

    case 6969:
      return '0x69da8ffe6550a3d78dbff368194d490fb30703f9'

    case 250:
      return '0xe236f6890f1824fa0a7ffc39b1597a5a6077cfe9'

    case 137:
      return '0x3fb1e7d5d9c974141a5b6e5fa4edab0a7aa15c6a'

    default:
      return '0x69da8ffe6550a3d78dbff368194d490fb30703f9'
  }
}

export const CHAIN_ID = {
  BSC: 56,
  TOMBCHAIN: 6969,
  FANTOM: 250,
  POLYGON: 137,
}

export const getScanUrl = (chainId) => {
  const id = Number(chainId)

  switch (id) {
    case CHAIN_ID.TOMBCHAIN:
      return `https://tombscout.com`

    case CHAIN_ID.BSC:
      return `https://bscscan.com`

    case CHAIN_ID.FANTOM:
      return `https://ftmscan.com`

    case CHAIN_ID.POLYGON:
      return `https://polygonscan.com`

    default:
      return `https://tombscout.com`
  }
}

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = ['https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json']

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0x6eec4ed9564b90e3b16aa72aece22bbb4c2ccb66',
  '0xc2b9a4775626584cfdedd8987980333f3a1c274f',
  '0x99629a6b918a693796a2f1a9a777f49c77602442',
  '0x80514a0406da411a01c953ee863517e5903abcce',
  '0x605c79b5c60a9d4675c87146d57d275624335381',
  '0x4200000000000000000000000000000000000103',
  '0x4200000000000000000000000000000000000102',
]

// pair blacklist
export const PAIR_BLACKLIST = []

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = []

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or Tomb pairings.'

// tokens that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES = [
  '0x26519b547416e4f53f3a4b05b95ef859c3bd89fe',
  '0xf6c7197eaff13002a7b2a2e835a7bb6969a4b026',
  '0xd77fc9c4074b56Ecf80009744391942FBFDDd88b',
]
