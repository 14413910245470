import React, { useState } from 'react'
import styled from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import { client } from './apollo/client'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import GlobalPage from './pages/GlobalPage'
import TokenPage from './pages/TokenPage'
import PairPage from './pages/PairPage'
import { useGlobalData, useGlobalChartData } from './contexts/GlobalData'
import { isAddress } from './utils'
import AllTokensPage from './pages/AllTokensPage'
import AllPairsPage from './pages/AllPairsPage'
import AppBgImage from './assets/sky.svg'
import { useDarkModeManager } from './contexts/LocalStorage'
import SideNav from './components/SideNav'
import LocalLoader from './components/LocalLoader'
import { useLatestBlocks } from './contexts/Application'
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from './constants'
import PinnedData from './components/PinnedData'
import AccountLookup from './pages/AccountLookup'
import { Header } from './components/Header'
import { FooterGlobal } from './components/FooterGlobal'
import TagManager from 'react-gtm-module'
import { useMedia } from 'react-use'

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.appBg};
  min-height: 100vh;
  z-index: 1;

  @media screen and (max-width: 1080px) {
    overflow: hidden;
  }
`
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ open }) => (open ? '220px 1fr 200px' : '220px 1fr 60px')};
  min-height: 100vh;

  @media screen and (max-width: 1400px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    overflow: hidden;
  }
`

const Right = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  width: ${({ open }) => (open ? '220px' : '64px')};
  height: ${({ open }) => (open ? 'fit-content' : '64px')};
  overflow: auto;

  @media screen and (max-width: 1400px) {
    display: none;
  }
`

const Center = styled.div`
  height: 100%;
  z-index: 100;
  transition: width 0.25s ease;

  @media screen and (max-width: 1080px) {
    padding-bottom: 100px;
  }
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: #ff6978;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

const AppBg = styled.div`
  position: absolute;
  top: -120px;
  left: 0;
  width: 100%;
  height: 1110px;
  pointer-events: none;
  z-index: -1;
  overflow: hidden;
`

const BgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  min-height: 934px;
  opacity: ${({ theme }) => (theme.darkMode ? '0.6' : '0.35')};
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  const below768 = useMedia('(max-width: 768px)')

  return (
    <>
      <AppBg>
        <BgImage src={AppBgImage} />
      </AppBg>
      <Header />
      <ContentWrapper open={savedOpen}>
        {below768 ? <SideNav /> : <div />}
        <Center id="center">{children}</Center>
        <Right open={savedOpen}>
          <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
        </Right>
      </ContentWrapper>
      <FooterGlobal />
    </>
  )
}

const BLOCK_DIFFERENCE_THRESHOLD = 300

function App() {
  const [savedOpen, setSavedOpen] = useState(false)

  const globalData = useGlobalData()
  const globalChartData = useGlobalChartData()
  const [latestBlock, headBlock] = useLatestBlocks()

  // show warning
  const showWarning = headBlock && latestBlock ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD : false

  const tagManagerArgs = {
    gtmId: 'GTM-PMTV246',
  }

  TagManager.initialize(tagManagerArgs)

  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        {showWarning && (
          <WarningWrapper>
            <WarningBanner>
              {`Warning: The data on this site has only synced to TombChain block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </WarningWrapper>
        )}
        {globalData &&
        Object.keys(globalData).length > 0 &&
        globalChartData &&
        Object.keys(globalChartData).length > 0 ? (
          <>
            <BrowserRouter>
              <Switch>
                <Route
                  exacts
                  strict
                  path="/token/:tokenAddress"
                  render={({ match }) => {
                    if (
                      isAddress(match.params.tokenAddress.toLowerCase()) &&
                      !Object.keys(TOKEN_BLACKLIST).includes(match.params.tokenAddress.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <TokenPage address={match.params.tokenAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />
                <Route
                  exacts
                  strict
                  path="/pair/:pairAddress"
                  render={({ match }) => {
                    if (
                      isAddress(match.params.pairAddress.toLowerCase()) &&
                      !Object.keys(PAIR_BLACKLIST).includes(match.params.pairAddress.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <PairPage pairAddress={match.params.pairAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />

                <Route path="/home">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <GlobalPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/tokens">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllTokensPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/pairs">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPairsPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/accounts">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AccountLookup />
                  </LayoutWrapper>
                </Route>

                <Redirect to="/home" />
              </Switch>
            </BrowserRouter>
          </>
        ) : (
          <LocalLoader isFull />
        )}
      </AppWrapper>
    </ApolloProvider>
  )
}

export default App
