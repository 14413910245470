import React from 'react'

const Logo = ({ ...props }) => {
  return (
    <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M128 256C198.692 256 256 198.692 256 128C256 57.3076 198.692 0 128 0C57.3076 0 0 57.3076 0 128C0 198.692 57.3076 256 128 256Z"
        fill="url(#paint0_radial_219_2081)"
      />
      <g filter="url(#filter0_d_219_2081)">
        <path
          d="M119.674 66.9415C118.34 68.2829 117.36 69.9142 116.795 71.6882L80.6333 168.01H66.048V182.784H93.6709L104.315 153.406L109.381 138.637L127.796 86.3417L161.758 182.784H189.946V168.01H175.304L139.145 71.6852C138.581 69.9126 137.601 68.2819 136.267 66.9415C134.067 64.7301 131.082 63.488 127.971 63.488C124.859 63.488 121.875 64.7301 119.674 66.9415Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_219_2081"
          x="62.048"
          y="63.488"
          width="131.898"
          height="127.296"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_219_2081" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_219_2081" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_219_2081"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(362.039 243.426)"
        >
          <stop stopColor="#79A5C6" />
          <stop offset="0.616783" stopColor="#2A4874" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default Logo
