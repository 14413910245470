import React from 'react'
import styled, { keyframes } from 'styled-components'
import Logo from '../Icons/Logo'

const pulse = keyframes`
 from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`

const Wrapper = styled.div`
  position: ${(props) => (props.isFull ? 'fixed' : 'relative')};
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isFull ? '100%' : '300px')};
`

const Box = styled.div`
  border-radius: 50%;
  height: ${(props) => (props.isFull ? '80px' : '40px')};
  width: ${(props) => (props.isFull ? '80px' : '40px')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TethysLogo = styled(Logo)`
  animation: ${pulse} 1200ms linear infinite;
  width: '100%';
  height: '100%';
`

const LocalLoader = ({ isFull = false }) => {
  return (
    <Wrapper isFull={isFull}>
      <Box isFull={isFull}>
        <TethysLogo />
      </Box>
    </Wrapper>
  )
}

export default LocalLoader
