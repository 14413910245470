import React, { useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import {
  ChainDropDown,
  ChainIcon,
  ChainToggleBox,
  ChainToggleBtn,
  ChainToggleItem,
  DesktopToggleText,
  StyledDropDown,
} from './styles'
import TombLogo from '../../assets/tombchainmini.svg'
import FantomLogo from '../../assets/fantom.svg'
import BscLogo from '../../assets/bscchain.svg'
import PolygonLogo from '../../assets/polygonchain.svg'
// import AvaLogo from '../../assets/avachain.svg'
// import EthLogo from '../../assets/ethchain.svg'
import { Text } from 'rebass'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { CHAIN_ID } from '../../constants'

export const ChainToggle: React.FC = (props) => {
  const location = useLocation()
  const history = useHistory()
  const chainId = queryString.parse(location.search).chainId ?? '250'

  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(menuRef, menuOpen ? () => setMenuOpen(!menuOpen) : undefined)

  const chains = [
    {
      id: CHAIN_ID.TOMBCHAIN,
      logo: TombLogo,
      name: 'Tomb Chain',
      color: '#6B00FA',
    },
    {
      id: CHAIN_ID.FANTOM,
      logo: FantomLogo,
      name: 'Fantom',
      color: '#1969FF',
    },
    {
      id: CHAIN_ID.BSC,
      logo: BscLogo,
      name: 'BNB Chain',
      color: '#F0B90B',
    },
    {
      id: CHAIN_ID.POLYGON,
      logo: PolygonLogo,
      name: 'Polygon',
      color: '#8247E5',
    },
    // {
    //   id: 3,
    //   logo: PolygonLogo,
    //   name: 'Polygon',
    //   color: '#8247E5',
    // },
    // {
    //   id: 4,
    //   logo: AvaLogo,
    //   name: 'Avalanche',
    //   color: '#E84142',
    // },
    // {
    //   id: 5,
    //   logo: EthLogo,
    //   name: 'Etherium',
    //   color: '#959595',
    // },
  ]
  const currentChain = chains.find((item) => item.id === Number(chainId))

  const selectChain = (chainId) => {
    history.push({ search: `?chainId=${chainId}` })
    history.go(0)
    setMenuOpen(false)
  }

  return (
    <ChainToggleBox ref={menuRef}>
      <ChainToggleBtn bg={currentChain?.color} onClick={() => setMenuOpen(!menuOpen)}>
        <ChainIcon src={currentChain?.logo} />

        <DesktopToggleText>
          <Text fontSize="14px" lineHeight="18px">
            {currentChain?.name}
          </Text>
          <StyledDropDown selected={menuOpen} />
        </DesktopToggleText>
      </ChainToggleBtn>
      {menuOpen && (
        <ChainDropDown>
          {chains.map((chain) => {
            return (
              <ChainToggleItem onClick={() => selectChain(chain.id)} bg={chain.color} key={chain.id}>
                <ChainIcon src={chain.logo} />

                <DesktopToggleText>
                  <Text fontSize="14px" lineHeight="18px">
                    {chain.name}
                  </Text>
                </DesktopToggleText>
              </ChainToggleItem>
            )
          })}
        </ChainDropDown>
      )}
    </ChainToggleBox>
  )
}
