import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import queryString from 'query-string'

const chainId = queryString.parse(location.search).chainId ?? '250'

const getUrl = (chainId) => {
  const id = Number(chainId)

  switch (id) {
    case 56:
      return {
        client: 'https://api.thegraph.com/subgraphs/name/ruvlol/lif3-bsc',
        health: 'https://api.thegraph.com/index-node/graphql',
        block: 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks',
      }

    case 137:
      return {
        client: 'https://api.thegraph.com/subgraphs/name/ruvlol/lif3-matic',
        health: 'https://api.thegraph.com/index-node/graphql',
        block: 'https://api.thegraph.com/subgraphs/name/matthewlilley/polygon-blocks',
      }

    case 6969:
      return {
        client: 'https://graph-node.lif3.com/subgraphs/name/lifeswap',
        health: 'https://graph-node.lif3.com/index_node/graphql',
        block: 'https://graph-node.lif3.com/subgraphs/name/tombchainblocks',
      }

    case 250:
      return {
        client: 'https://graph-node.tomb.com/subgraphs/name/tombswap-subgraph',
        health: 'https://graph-node.tomb.com/index_node/graphql',
        block: 'https://api.thegraph.com/subgraphs/name/matthewlilley/fantom-blocks',
      }

    default:
      return {
        client: 'https://graph-node.tomb.com/subgraphs/name/tombswap-subgraph',
        health: 'https://graph-node.tomb.com/index_node/graphql',
        block: 'https://api.thegraph.com/subgraphs/name/matthewlilley/fantom-blocks',
      }
  }
}

export const client = new ApolloClient({
  link: new HttpLink({
    uri: getUrl(chainId).client,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: getUrl(chainId).health,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: getUrl(chainId).block,
  }),
  cache: new InMemoryCache(),
})
