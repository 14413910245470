import React, { useState } from 'react'
import styled from 'styled-components'
import { RowFixed, RowBetween } from '../Row'
import { useMedia } from 'react-use'
import { useGlobalData, useEthPrice } from '../../contexts/GlobalData'
import { formattedNum, localNumber } from '../../utils'

import UniPrice from '../UniPrice'
import { TYPE } from '../../Theme'

const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  margin-bottom: 36px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 42px;
  }
`
const StatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px 30px;

  @media screen and (min-width: 1024px) {
    gap: 18px 36px;
  }
`

export default function GlobalStats() {
  const [showPriceCard, setShowPriceCard] = useState(false)

  const { oneDayVolumeUSD, oneDayTxns, pairCount } = useGlobalData()
  const [ethPrice] = useEthPrice()
  const formattedEthPrice = ethPrice ? formattedNum(ethPrice, true) : '-'
  const oneDayFees = oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD * 0.002, true) : ''

  return (
    <Header>
      <RowBetween>
        <StatsRow>
          <TYPE.main
            onMouseEnter={() => {
              setShowPriceCard(true)
            }}
            onMouseLeave={() => {
              setShowPriceCard(false)
            }}
            style={{ position: 'relative' }}
          >
            TOMB Price: {formattedEthPrice}
            {/* {showPriceCard && <UniPrice />} */}
          </TYPE.main>

          <TYPE.main>Transactions (24H): {localNumber(oneDayTxns)}</TYPE.main>
          {/* <TYPE.main>Pairs: {localNumber(pairCount)}</TYPE.main> */}
          {/* <TYPE.main>Fees (24H): {oneDayFees}&nbsp;</TYPE.main> */}
        </StatsRow>
      </RowBetween>
    </Header>
  )
}
