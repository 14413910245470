import React from 'react'

const Tokens = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.35288 15.0496L2.6227 15.2209L3.35288 15.0496ZM3.35288 8.95043L2.6227 8.77915L3.35288 8.95043ZM20.6471 8.95044L19.9169 9.12171L20.6471 8.95044ZM20.6471 15.0496L19.9169 14.8783L20.6471 15.0496ZM15.0496 20.6471L14.8783 19.9169L15.0496 20.6471ZM8.95044 20.6471L8.77916 21.3773L8.95044 20.6471ZM8.95043 3.35288L8.77916 2.6227L8.95043 3.35288ZM15.0496 3.35288L14.8783 4.08306L15.0496 3.35288ZM4.08306 14.8783C3.63898 12.9851 3.63898 11.0149 4.08306 9.12171L2.6227 8.77915C2.12577 10.8976 2.12577 13.1024 2.6227 15.2209L4.08306 14.8783ZM19.9169 9.12171C20.361 11.0149 20.361 12.9851 19.9169 14.8783L21.3773 15.2208C21.8742 13.1024 21.8742 10.8976 21.3773 8.77916L19.9169 9.12171ZM14.8783 19.9169C12.9851 20.361 11.0149 20.361 9.12171 19.9169L8.77916 21.3773C10.8976 21.8742 13.1024 21.8742 15.2208 21.3773L14.8783 19.9169ZM9.12171 4.08306C11.0149 3.63898 12.9851 3.63898 14.8783 4.08306L15.2208 2.6227C13.1024 2.12577 10.8976 2.12577 8.77916 2.6227L9.12171 4.08306ZM9.12171 19.9169C6.62161 19.3305 4.6695 17.3784 4.08306 14.8783L2.6227 15.2209C3.33924 18.2756 5.72441 20.6608 8.77916 21.3773L9.12171 19.9169ZM15.2208 21.3773C18.2756 20.6608 20.6608 18.2756 21.3773 15.2208L19.9169 14.8783C19.3305 17.3784 17.3784 19.3305 14.8783 19.9169L15.2208 21.3773ZM14.8783 4.08306C17.3784 4.6695 19.3305 6.62161 19.9169 9.12171L21.3773 8.77916C20.6608 5.72441 18.2756 3.33924 15.2208 2.6227L14.8783 4.08306ZM8.77916 2.6227C5.72441 3.33924 3.33924 5.72441 2.6227 8.77915L4.08306 9.12171C4.6695 6.6216 6.62161 4.6695 9.12171 4.08306L8.77916 2.6227Z"
        fill="currentColor"
      />
      <path
        d="M9.11763 13.0165L8.38745 13.1878L9.11763 13.0165ZM9.11763 10.9835L8.38745 10.8122L9.11763 10.9835ZM14.8824 10.9835L14.1522 11.1548L14.8824 10.9835ZM14.8824 13.0165L14.1522 12.8452L14.8824 13.0165ZM13.0165 14.8824L12.8452 14.1522L13.0165 14.8824ZM10.9835 14.8824L10.8122 15.6126L10.9835 14.8824ZM10.9835 9.11763L10.8122 8.38744L10.9835 9.11763ZM13.0165 9.11763L12.8452 9.84781L13.0165 9.11763ZM9.84781 12.8452C9.7174 12.2893 9.7174 11.7107 9.84781 11.1548L8.38745 10.8122C8.20419 11.5935 8.20419 12.4065 8.38745 13.1878L9.84781 12.8452ZM14.1522 11.1548C14.2826 11.7107 14.2826 12.2893 14.1522 12.8452L15.6126 13.1878C15.7958 12.4065 15.7958 11.5935 15.6126 10.8122L14.1522 11.1548ZM12.8452 14.1522C12.2893 14.2826 11.7107 14.2826 11.1548 14.1522L10.8122 15.6126C11.5935 15.7958 12.4065 15.7958 13.1878 15.6126L12.8452 14.1522ZM11.1548 9.84781C11.7107 9.7174 12.2893 9.7174 12.8452 9.84781L13.1878 8.38744C12.4065 8.20419 11.5935 8.20419 10.8122 8.38744L11.1548 9.84781ZM11.1548 14.1522C10.5063 14.0001 9.99992 13.4937 9.84781 12.8452L8.38745 13.1878C8.66966 14.3909 9.60907 15.3303 10.8122 15.6126L11.1548 14.1522ZM13.1878 15.6126C14.3909 15.3303 15.3303 14.3909 15.6126 13.1878L14.1522 12.8452C14.0001 13.4937 13.4937 14.0001 12.8452 14.1522L13.1878 15.6126ZM12.8452 9.84781C13.4937 9.99992 14.0001 10.5063 14.1522 11.1548L15.6126 10.8122C15.3303 9.60907 14.3909 8.66966 13.1878 8.38744L12.8452 9.84781ZM10.8122 8.38744C9.60907 8.66966 8.66966 9.60907 8.38745 10.8122L9.84781 11.1548C9.99992 10.5063 10.5063 9.99992 11.1548 9.84781L10.8122 8.38744Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Tokens
