import styled from 'styled-components'
import { ReactComponent as DropDown } from '../../assets/dropdown.svg'

export const ChainToggleBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const ChainToggleBtn = styled.button<{ bg?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 10px 12px;
  min-width: 152px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  background: ${({ bg }) => (bg ? bg : 'radial-gradient(100% 100% at 0% 0%, #79A5C6 0%, #2A4874 100%)')};
  border-radius: 22px;
  border: none;
  font: inherit;
  color: ${({ theme }) => theme.text1};
  cursor: pointer;

  @media screen and (max-width: 1080px) {
    width: 42px;
    min-width: auto;
    height: 42px;
    border-radius: 50%;
  }
`

export const ChainToggleItem = styled(ChainToggleBtn)<{ bg?: string }>`
  padding: 10px 20px;
  background: transparent;
  box-shadow: none;
  min-width: auto;
  justify-content: flex-start;
  transition: opacity 0.3s, background-color 0.3s;
  text-decoration: none;

  @media screen and (max-width: 1080px) {
    justify-content: center;
    background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  }

  &:hover {
    background-color: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  }
`

export const StyledDropDown = styled(DropDown)<{ selected?: boolean }>`
  height: 35%;
  transform: ${({ selected }) => (selected ? 'rotate(180deg)' : 'none')};
  transform-origin: center;
  path {
    stroke: ${({ theme }) => theme.text1};
    stroke-width: 1.5px;
  }
`

export const ChainIcon = styled.img`
  max-width: 26px;
  max-height: 22px;
  min-width: 22px;
`

export const ChainDropDown = styled.span`
  border-radius: 20px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: calc(100% + 20px);
  left: -10px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14), 0px 10.125px 17.4375px rgba(0, 0, 0, 0.0945),
    0px 4px 6.5px rgba(0, 0, 0, 0.07), 0px 0.875px 2.3125px rgba(0, 0, 0, 0.0455);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  @media screen and (max-width: 1080px) {
    padding: 6px 0;
    left: -5px;
    top: auto;
    bottom: calc(100% + 20px);
    align-items: center;
    width: calc(100% + 10px);
  }
`

export const DesktopToggleText = styled.span`
  display: none;

  @media screen and (min-width: 1080px) {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`
