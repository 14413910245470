import React, { useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { Box } from 'rebass/styled-components'
import Toggle from '../Toggle'
import Tokens from '../Icons/Tokens.js'
import Pairs from '../Icons/Pairs.js'
import Overview from '../Icons/Overview.js'
import { Lif3Icon as Lif3 } from '../Icons/LIF3'
import { useDarkModeManager } from '../../contexts/LocalStorage'

import { RowFixed } from '../Row'
import { StyledMenuButton } from '../StyledMenu'
import { LogoHeader } from '../Icons/LogoHeader'
import { SwapIcon } from '../Icons/SwapIcon'
import { LiquidityIcon } from '../Icons/LiquidityIcon'
import { FarmIcon } from '../Icons/FarmIcon'
import { StakeIcon } from '../Icons/StakeIcon'
import { TwitterIcon } from '../Icons/TwitterIcon'

import { DiscordIcon } from '../Icons/DiscordIcon'
import { BridgeIcon } from '../Icons/BridgeIcon'
import { AnalyticsIcon } from '../Icons/AnalyticsIcon'
import { TelegramIcon } from '../Icons/TelegramIcon'
import { DocsIcon } from '../Icons/DocsIcon'
import { GovernanceIcon } from '../Icons/GovernanceIcon'
import { LendIcon } from '../Icons/LendIcon'
import { MediumIcon } from '../Icons/MediumIcon'
import { LogoMini } from '../Icons/LogoMini'
import { SingleIcon } from '../Icons/SingleIcon'
import { ReactComponent as FantomIcon } from '../../assets/fantom.svg'
import { ReactComponent as SkullIcon } from '../../assets/skull.svg'
import { BaseBtn } from '../ButtonStyled'
import { WalletIcon } from '../Icons/WalletIcon'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ReactComponent as DropDownIcon } from '../../assets/dropdown.svg'
import { GenesisIcon } from '../Icons/GenesisIcon'
import { ChainToggle } from '../ChainToggle'

const HeaderFrame = styled.div`
  top: 0;
  position: relative;
  padding: 15px 20px 25px;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 720px) {
    padding: 10px 20px 45px;
  }
`

const HeaderRow = styled(RowFixed)`
  width: 100%;
  display: grid;
  grid-template-columns: 260px 1fr 260px;

  @media screen and (max-width: 1760px) {
    grid-template-columns: 150px 1fr 150px;
  }

  @media screen and (max-width: 1400px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const HeaderLinks = styled.div<{ isLanding?: boolean }>`
  display: flex;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.25) 49.48%,
    rgba(255, 255, 255, 0.25) 69.79%,
    rgba(255, 255, 255, 0.25) 100%
  );
  border-radius: 30px;
  padding: 0 8px 0 14px;
  height: 58px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }
`

const HeaderChainToggle = styled.div`
  margin-left: 10px;
`

const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 30px;

  @media screen and (max-width: 1080px) {
    margin-right: 0;
    grid-column: 2 / 3;
    justify-self: center;
  }

  @media screen and (max-width: 720px) {
    margin-right: 20px;
  }

  :hover {
    cursor: pointer;
  }
`

const HeaderLogoText = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  display: inline-block;
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  pointer-events: none;

  @media screen and (max-width: 1200px) {
    top: calc(100% + 25px);
  }

  @media screen and (max-width: 1080px) {
    left: -50%;
  }

  @media screen and (max-width: 980px) {
    top: calc(100% + 15px);
  }

  & > * {
    display: inline-block;
    vertical-align: middle;
  }
`

const StaticIcon = styled.div`
  display: flex;
  align-items: center;
`

const LogoDesktop = styled.div`
  display: flex;
  align-items: center;
`

const activeClassName = 'ACTIVE'

const StLinkIcon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  @media screen and (max-width: 720px) {
    margin-right: 0px;
    margin-top: 8px;
  }
`

const StLink = styled.a<{ isActive: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: left;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  width: fit-content;
  font-size: 15px;
  line-height: 20px;
  transition: all 0.3s;
  align-items: center;
  padding: 0 12px;
  min-height: 30px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: ${({ isActive }) => (isActive ? '1' : '0')};
    transition: opacity 0.3s;

    background: linear-gradient(91.08deg, #39404e 1.23%, #373f4c 100%);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    content: '';
  }

  ${StLinkIcon} {
    color: ${({ theme, isActive }) => (isActive ? theme.green : 'inherit')};
  }

  &:hover ${StLinkIcon} {
    color: ${({ theme }) => theme.green};
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:hover:after {
    opacity: 1;
  }

  @media screen and (max-width: 1400px) {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1200px) {
    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  @media screen and (max-width: 720px) {
    flex-direction: column;
    font-size: 10px;
  }
`

const StLinkLarge = styled(StLink)`
  @media screen and (max-width: 1560px) {
    display: none;
  }
`

const glareAnimation = keyframes`
 0% { right: -20%; }
 5% {
   right: 100%;
 }
 10%, 100% { right: -20%; }
`

const StLinkText = styled.span`
  position: relative;
`

const StyledExternalLink = styled.a.attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: row nowrap;
  align-items: left;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, isActive }) => theme.white};
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  transition: all 0.3s;
  align-items: center;
  min-height: 30px;
  padding: 0 12px;
  background: linear-gradient(91.08deg, #39404e 1.23%, #373f4c 100%);

  &:not(:last-child) {
    margin-right: 20px;
  }

  :hover {
    background: linear-gradient(91.08deg, #39404e 1.23%, #373f4c 100%);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }

  @media screen and (max-width: 1400px) {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1200px) {
    display: none;

    &:not(:last-child) {
      margin-right: 2px;
      border-radius: 10px;
    }
  }
`

const Web3Button = styled(BaseBtn)<{ isActive: boolean }>`
  height: 36px;
  background: ${({ theme }) => theme.componentBg1};
  color: ${({ theme }) => theme.white};
  padding: 0 20px;
  width: 160px;
  transition: all 0.3s;
  display: flex;
  font-weight: 400;
  align-items: center;
  font-size: 14px;
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.green : 'rgba(255,255,255,0.15)')};

  &:hover {
    border: 1px solid ${({ theme, isActive }) => (isActive ? theme.green : theme.white)};
  }
`

const StyledDropIcon = styled(DropDownIcon)<{ selected: boolean }>`
  margin-left: 5.5px;
  height: 35%;
  transform: ${({ selected }) => (selected ? 'rotate(180deg)' : 'rotate(0deg)')};

  path {
    stroke: ${({ theme }) => theme.text1};
    stroke-width: 1.5px;
  }
`

const StyledDropBtn = styled.button<{ isActive: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  position: relative;
  align-items: left;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  flex-shrink: 0;
  width: fit-content;
  font-size: 15px;
  line-height: 20px;
  transition: all 0.3s;
  align-items: center;
  padding: 0 12px;
  min-height: 30px;
  border: none;
  background: transparent;
  gap: 8px;
  margin-right: 20px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: ${({ isActive }) => (isActive ? '1' : '0')};
    transition: opacity 0.3s;

    background: linear-gradient(91.08deg, #39404e 1.23%, #373f4c 100%);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    content: '';
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &:hover {
    color: ${({ theme }) => theme.text1};
  }

  &:hover ${StyledDropIcon} {
    color: ${({ theme }) => theme.text1};
  }

  &:hover:after {
    opacity: 1;
  }

  @media screen and (max-width: 1610px) {
    margin-right: 8px;
  }

  @media screen and (max-width: 1200px) {
    border-radius: 10px;
  }
`

const OtherBox = styled(Box)`
  gap: 15px;
`
const MenuFlyout = styled.span`
  width: 160px;
  background: ${({ theme }) => theme.appBg};
  box-shadow: ${({ theme }) => theme.contrastShadow};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 100;
`
const OtherFlyout = styled(MenuFlyout)`
  width: 160px;
  right: auto;
  left: -40px;
  padding: 10px 0;
  background: rgba(255, 242, 209, 0.15);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
`
const SocialIconWrapper = styled(Box)`
  color: ${({ theme }) => theme.text1};
  transition: all 0.3s;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const MenuItemLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  display: flex;
  font-weight: 500;
  align-items: center;
  padding: 3px 12px;
  transition: 0.3s all;
  white-space: nowrap;
  color: ${({ theme }) => theme.text1};

  &:hover {
    color: ${({ theme }) => theme.green};
  }

  &:hover ${SocialIconWrapper} {
    color: ${({ theme }) => theme.green};
  }
`

const MenuItemLinkSmall = styled(MenuItemLink)`
  @media screen and (max-width: 1560px) {
    display: block;
  }
`

const HeaderThemeToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  padding: 0 12px;
  align-self: center;
`

type Props = {
  isLanding?: boolean
}

export const Header: React.FC<Props> = (props) => {
  const { isLanding } = props
  const [isOtherMenuOpen, toggleOtherMenu] = useState(false)
  const otherRef = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(otherRef, isOtherMenuOpen ? () => toggleOtherMenu(!isOtherMenuOpen) : undefined)
  const { pathname } = useLocation()
  const [isDark, toggleDarkMode] = useDarkModeManager()

  return (
    <HeaderFrame>
      <HeaderRow>
        <Title>
          <StaticIcon>
            <a style={{ textDecoration: 'none' }} href="https://lif3.com/">
              <LogoDesktop>
                <LogoHeader />
              </LogoDesktop>
            </a>
          </StaticIcon>
        </Title>
        <HeaderLinks isLanding={isLanding}>
          <StLink as={Link} isActive={pathname === '/home'} to={'/home'}>
            <StLinkIcon>
              <Overview />
            </StLinkIcon>
            <StLinkText>Overview</StLinkText>
          </StLink>
          <StLink as={Link} isActive={pathname === '/tokens'} to={'/tokens'}>
            <StLinkIcon>
              <Tokens />
            </StLinkIcon>
            <StLinkText>Tokens</StLinkText>
          </StLink>
          <StLink as={Link} isActive={pathname === '/pairs'} to={'/pairs'}>
            <StLinkIcon>
              <Pairs />
            </StLinkIcon>
            <StLinkText>Pools</StLinkText>
          </StLink>
          <a style={{ textDecoration: 'none' }} href="https://lif3.com/" target="_blank" rel="noreferrer">
            <StLink isActive={false}>
              <StLinkIcon>
                <Box marginTop={'12px'}>
                  <Lif3 />
                </Box>
              </StLinkIcon>
              <StLinkText>Lif3.com</StLinkText>
            </StLink>
          </a>
          {/* <StLink isActive={false} href="https://lif3.com/swap">
            <StLinkIcon>
              <SwapIcon />
            </StLinkIcon>
            <StLinkText>Swap</StLinkText>
          </StLink>
          <StLink isActive={false} href="https://lif3.com/garden">
            <StLinkIcon>
              <FarmIcon />
            </StLinkIcon>
            <StLinkText>Garden</StLinkText>
          </StLink>
          <StLink isActive={false} href="https://lif3.com/terrace">
            <StLinkIcon>
              <SingleIcon />
            </StLinkIcon>
            <StLinkText>Terrace</StLinkText>
          </StLink>
          <StLink isActive={false} href="https://lif3.com/staking/lif3">
            <StLinkIcon>
              <LendIcon />
            </StLinkIcon>
            <StLinkText>Greenhouse</StLinkText>
          </StLink> */}
          {/* <StLinkLarge isActive={false} href="https://lif3.com/nursery">
            <StLinkIcon>
              <GenesisIcon />
            </StLinkIcon>
            <StLinkText>Nursery</StLinkText>
          </StLinkLarge> */}
          {/* <StyledExternalLink href="https://info.lif3.com/">
            <StLinkIcon>
              <AnalyticsIcon />
            </StLinkIcon>
            Analytics
          </StyledExternalLink> */}

          {/* <div ref={otherRef} style={{ position: 'relative' }}>
            <StyledDropBtn isActive={isOtherMenuOpen} onClick={() => toggleOtherMenu(!isOtherMenuOpen)}>
              <StLinkText>More</StLinkText>
              <StyledDropIcon selected={isOtherMenuOpen} />
            </StyledDropBtn>
            {isOtherMenuOpen && (
              <OtherFlyout>
                <OtherBox pt="14px" pb="14px" display="flex" flexDirection="column" width="100%">
                  <MenuItemLink href="https://lif3.com/pool/add">
                    <SocialIconWrapper>
                      <LiquidityIcon />
                    </SocialIconWrapper>
                    <Box ml="8px">Liquidity</Box>
                  </MenuItemLink>
                  <MenuItemLink href="https://lif3.com/bridge">
                    <SocialIconWrapper>
                      <BridgeIcon />
                    </SocialIconWrapper>
                    <Box ml="8px">Bridge</Box>
                  </MenuItemLink> */}
          {/* <MenuItemLinkSmall href="https://lif3.com/nursery">
                    <SocialIconWrapper>
                      <GenesisIcon />
                    </SocialIconWrapper>
                    <Box ml="8px">Nurcery</Box>
                  </MenuItemLinkSmall> */}
          {/* </OtherBox>
              </OtherFlyout>
            )}
          </div> */}
          {/* <a style={{ textDecoration: 'none' }} href="https://lif3.com/portfolio" target="_blank" rel="noreferrer">
            <Web3Button>
              <Box
                color="#ffffff"
                mr="8px"
                width="18px"
                height="18px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <WalletIcon />
              </Box>
              My portfolio
            </Web3Button>
          </a> */}

          <HeaderChainToggle>
            <ChainToggle />
          </HeaderChainToggle>
          <HeaderThemeToggle>
            <Toggle isActive={isDark} toggle={toggleDarkMode} />
          </HeaderThemeToggle>
        </HeaderLinks>
      </HeaderRow>
    </HeaderFrame>
  )
}
