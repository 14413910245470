import React from 'react'

export const Lif3Icon: React.FC = () => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_525_16166)">
        <path
          d="M10.2843 0.4047C10.1279 0.5619 10.0131 0.75306 9.94696 0.96096L5.70922 12.2487H4V13.98H7.23706L8.48446 10.5373L9.0781 8.80656L11.2361 2.67816L15.216 13.98H18.5193V12.2487H16.8035L12.5661 0.9606C12.5 0.75288 12.3851 0.56178 12.2288 0.4047C11.9709 0.14556 11.6212 0 11.2566 0C10.8919 0 10.5422 0.14556 10.2843 0.4047Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_525_16166"
          x="0"
          y="0"
          width="22.5195"
          height="21.98"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_525_16166" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_525_16166" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
